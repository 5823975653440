<template>
<div>
  <div class="air__utils__heading">
    <h5>Almacenes</h5>
  </div>
  <my-breadcrumbs :routes="routes" class="my-4"/>
  <div class="row justify-content-center mt-1 mb-4 py-3 bg-white">
    <div class="col">
      <globalPlantFilter @filterValue="filterPlant = $event"/>
    </div>
  </div>
  <!-- Filtros-->
  <a-row class="mb-4 mt-4" align="middle" :gutter="[8,24]">
    <!-- Select de Almacene-->
    <a-col :span="12">
      <ResourceSelect v-model="WarehouseFilter"
                      urlResource="/warehouses"
                      :filters="warehouseComboFilters"
                      @change="FetchWarehouses()"
                      placeholder="Almacén (Seleccione una planta)">
        <span slot-scope="{option}">{{option.name}}</span>
      </ResourceSelect>
    </a-col>
    <!-- Select de Corriente-->
    <a-col :span="12">
      <ResourceSelect v-model="currentFilter"
                      @change="FetchWarehouses()"
                      urlResource="/currents"
                      :filters="defaulFilters"
                      placeholder="Corriente">
        <span slot-scope="{option}">{{option.name}}</span>
      </ResourceSelect>
    </a-col>
    <!-- Select de Tipo de Contenedor-->
    <a-col :span="12">
      <ResourceSelect v-model="currentFilter"
                      urlResource="/containerTypes"
                      :filters="defaulFilters"
                      @change="FetchWarehouses()"
                      placeholder="Tipo de Contenedor">
        <span slot-scope="{option}">{{option.name}}</span>
      </ResourceSelect>
    </a-col>
    <!--DATEPICKER - Mes-->
    <a-col :span="10">
      <a-month-picker v-model="monthFilter" @change="FetchWarehouses()" style="width: 100%"
                      placeholder="Seleccionar Mes" valueFormat="YYYY,MM" format="MM/YYYY"/>
    </a-col>
    <a-col :span="1">
      <b-button variant="primary" @click="FetchWarehouses()">
        <b-icon icon="search"></b-icon>
      </b-button>
    </a-col>
  </a-row>
  <!-- TABLA PRINCIPAL -->
  <div class="bg-white" v-show="filterPlant">
    <a-table class="mt-5"
             :columns="warehousesTable.columns"
             :data-source="warehousesTable.data"
             :pagination="false"
             :loading="warehousesTable.loading"
             @change="FetchWarehouses"
             bordered>
      <template slot="general" slot-scope="data">
        <a-descriptions bordered size="small" :column="{ sm: 1, md: 2 }">
          <a-descriptions-item label="Almacén">
            <strong>{{data.warehouse_code}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Corriente">
            <strong>{{data.current_code}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Contenedores">
            <strong>{{ data.container_quantity }} -{{ data.container_code}}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Cantidad Real">
            <strong>{{ data.real_quantity }} -{{data.um_code}}</strong>
          </a-descriptions-item>
        </a-descriptions>
      </template>
    </a-table>
  </div>
  <!-- TABLA PRINCIPAL -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import ResourceSelect from '@/components/ResourceSelect'
export default {
  name: 'warehouseReport',
  mixins: [fractalMixin],
  components: {
    ResourceSelect,
  },
  computed: {
    warehouseComboFilters() {
      return {
        'filter[type]': 'Operaciones',
        'filter[workCenters]': this.filterPlant,
        'fields[warehouses]': 'name',
      }
    },
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Reportes',
          name: 'reports',
        },
        {
          name: 'operation_reports',
          breadcrumbName: 'Reportes de Operaciones',
        },
        {
          breadcrumbName: 'Reporte de Almacén',
          aIcon: '',
        },
      ],
      filterPlant: '',
      // Tabla de almacenes
      warehousesTable: {
        data: [],
        loading: false,
        columns: [
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'general' },
          },
        ],
      },
      // filters tab de almacen
      currentFilter: undefined,
      containerTypeFilter: undefined,
      monthFilter: null,
      defaulFilters: { sort: 'name' },
      WarehouseFilter: undefined,
    }
  },
  methods: {
    async FetchWarehouses() {
      const params = {}
      if (this.WarehouseFilter) params.warehouses = this.WarehouseFilter
      if (this.currentFilter) params.currents = this.currentFilter
      if (this.containerTypeFilter) params.containerTypes = this.containerTypeFilter
      if (this.monthFilter) params.monthYear = this.monthFilter
      const response = await this.AxiosGet('/warehouse/statistics', params)
      this.warehousesTable.data = response.data.data
    },
  },
}
</script>

<style scoped>

</style>
